.rowIonItem {
  height: 2.5rem;

  --padding-end: 0;
  --inner-padding-end: 0;
}

.refreshIcon {
  font-size: 1.4rem;
  margin-inline: 0;
}

.taskIcon {
  font-size: 1.8rem;
  color: var(--ion-color-medium);
}

.ionLabel {
  font-weight: 400;
  font-size: 1rem;
}

ion-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}

.subtextContainer {
  display: flex;
  color: var(--ion-color-medium);
  flex-wrap: wrap;
  gap: 0;

  div {
    display: flex;
    gap: 0.1rem;
    margin-right: 0.2rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}

.taskItem {
  --detail-icon-color: var(--ion-color-medium);
  --detail-icon-font-size: 0.9rem;
  --detail-icon-opacity: 1;
  --padding-start: 0.6rem;
  --padding-top: 0.3rem;
  --padding-bottom: 0.3rem;

  ion-icon {
    padding: 0.4rem;
    font-size: 1.2rem;
  }

  .itemContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    ion-label {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .iconContainer {
    margin-right: 1rem;
  }

  .statusContainer {
    display: flex;
    align-items: flex-end;
    color: var(--ion-color-medium);
    gap: 0.4rem;
    white-space: nowrap;
    flex: column nowrap;
    flex-direction: column;

    div {
      display: flex;
      gap: 0.2rem;
      justify-content: flex-end;
    }

    ion-icon {
      font-size: 1.3rem;
    }
  }

  &.actionable {
    cursor: pointer;
  }

  &.notStarted {
    .iconContainer {
      ion-icon {
        /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
        background-color: rgb(var(--ion-color-primary-rgb), 0.1);
        color: var(--ion-color-primary);
      }
    }
  }

  &.locked {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-light);
        color: var(--ion-color-medium);
      }
    }

    .itemContentContainer {
      opacity: 0.65;

      ion-label {
        font-weight: 400;
        font-size: 1rem;
        color: var(--ion-color-text);
      }

      .subtextContainer {
        color: var(--ion-color-medium);
      }
    }
  }

  &.nextStep {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
      }
    }
  }

  &.skipped {
    .iconContainer {
      ion-icon {
        background-color: var(--ion-color-warning-tint);
        color: var(--ion-color-medium);
      }
    }
  }

  &.completed {
    .iconContainer {
      /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
      background-color: rgb(var(--ion-color-primary-rgb), 0.1);
      padding: 0.4rem;

      ion-icon {
        padding: 0.125rem;
        font-size: 0.9rem;
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        border-radius: 0.25rem;
      }
    }
  }

  &.unactionable {
    opacity: 0.5;
  }
}

.ionRefresher {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary);
}

.nextTaskItem {
  --padding-start: 0.6rem;
  --padding-top: 0.3rem;
  --padding-bottom: 0.3rem;

  &.menuInteraction {
    --padding-top: unset;
    --padding-bottom: unset;
    --inner-padding-end: 0;
    --padding-end: 0;
  }

  @media only screen and (max-width: 500px) {
    max-height: 3.37rem;
  }

  .alignEndContainer {
    display: flex;
    flex-direction: column;

    &.menuInteraction {
      align-items: center;
      height: 100%;
      margin: 0;
      justify-content: center;
      width: 3.3rem;
      cursor: pointer;

      --padding-top: 0;
      --inner-padding-end: 0;

      &.flipping {
        height: 6.25rem;
      }

      .horizontalHr {
        margin: 1rem 0;
        border: none;
        border-top: 0.1rem solid var(--ion-color-primary);
        transform: rotate(90deg);
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-transform: rotate(90deg);
        position: absolute;
        width: 5.7rem;
        z-index: 20000;
        height: 1.6rem;
        right: 1.3rem;

        @media only screen and (max-width: 500px) {
          z-index: 20000;
          height: 1.25rem;
          right: 2.2rem;
          width: 3.8rem;
        }

        &.isAndroid {
          width: 3.8rem;
          z-index: 20000;
          height: 1.5rem;
          right: 3rem;
        }

        &.flipping {
          border-top: 0.03rem solid var(--ion-background-color);
          width: 7rem;
          z-index: 20000;
          height: 3.6rem;
        }
      }
    }
  }

  .itemContainer {
    &.menuInteraction {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    display: flex;
    flex-direction: column;
    gap: 0.0125rem;

    .nextStepLabel {
      color: var(--ion-color-primary);
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }

  .outerContainer {
    &.menuInteraction {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }

    .container {
      margin-right: 0.5rem;
      /* stylelint-disable-next-line color-function-notation -- using rgb function for transparency */
      background-color: rgb(var(--ion-color-primary-rgb), 0.1);
      padding: 0.4rem;

      .icon {
        color: var(--ion-color-primary);
        font-size: 1.4rem;
      }
    }
  }

  .nextTaskActionRow {
    display: inline-flex;

    .checkMarkIcon {
      font-size: 1.1rem;
      margin-top: 0.2rem;
      margin-right: 0.25rem;
      color: var(--ion-color-light-shade);
      cursor: pointer;

      &:hover {
        color: var(--ion-color-light-contrast);
      }
    }

    .taskName {
      color: var(--ion-color-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      line-height: 1.2rem;

      @media only screen and (max-width: 380px) {
        width: 11rem;
      }

      @media only screen and (min-width: 380px) and (max-width: 500px) {
        width: 14rem;
      }

      @media only screen and (min-width: 600px) and (max-width: 760px) {
        max-width: 100%;
      }

      @media only screen and (min-width: 760px) and (max-width: 1000px) {
        width: 20rem;
      }
    }
  }

  .ellipsisIcon {
    color: var(--ion-color-primary);
  }

  &.flipping {
    --background: var(--ion-color-primary);
    --color: var(--ion-background-color);

    max-height: 100%;
    cursor: pointer;
    color: var(--ion-background-color);
    padding-inline-start: 1rem;

    .nextTaskActionRow {
      --background: var(--ion-color-primary);

      color: var(--ion-background-color);

      .taskName {
        color: var(--ion-background-color);
      }
    }

    .container {
      background: var(--ion-color-light-tint);
      margin-left: 0.3rem;

      .icon {
        color: var(--ion-color-primary);
      }
    }

    .nextStepLabel {
      color: var(--ion-background-color);
    }

    .ellipsisIcon {
      color: var(--ion-background-color);
    }
  }
}

.closeIcon {
  top: 0.6rem;
  font-size: 1.2rem;
  position: absolute;
  right: 0.438rem;
  color: var(--ion-color-primary-contrast);
  z-index: 9999;

  @media only screen and (max-width: 500px) {
    top: 0.2rem;
  }
}

.topHorizontalHr {
  margin: 1rem 0;
  border: none;
  border-top: 0.1rem solid var(--ion-color-primary);
  transform: rotate(90deg);
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-transform: rotate(90deg);
  position: absolute;
  width: 3.7rem;
  z-index: 20000;
  height: 1.6rem;
  right: 2.8rem;

  @media only screen and (max-width: 500px) {
    width: 3.3rem;
    z-index: 20000;
    height: 1.25rem;
    right: 3rem;
  }

  &.isAndroid {
    width: 3.8rem;
    z-index: 20000;
    height: 1.5rem;
    right: 3rem;
  }

  &.flipping {
    border-top: 0.03rem solid var(--ion-background-color);
    width: 7rem;
    z-index: 20000;
    height: 3.6rem;
    right: 2.3rem;
  }
}

.popOver {
  --width: 100%;

  margin-top: 1.7rem;
  margin-left: 0.5rem;

  @media only screen and (min-width: 500px) {
    --max-width: 46vw;

    margin-left: 1.1rem;
  }

  @media only screen and (min-width: 1440px) {
    --max-width: 48vw;

    margin-left: 1rem;
  }

  @media only screen and (max-width: 500px) {
    --max-width: 95%;

    margin-left: 0.8rem;
  }

  ion-item {
    cursor: pointer;
  }

  &.onChat {
    margin-top: -13.5rem;
  }

  &.flipping {
    margin-top: -14.5rem;

    --width: 22rem;

    @media only screen and (max-width: 500px) {
      --max-width: 46vw;

      margin-left: 1.1rem;
    }
  }
}

.removeProject {
  color: var(--ion-color-danger);
}
